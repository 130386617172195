import { HttpClient,  HttpParams, HttpResponse, withFetch } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataLogicService<T> {

    private _httpClient = inject(HttpClient)



    /** fetch signal data from api */
    getSingleData({ url, params }: { url: string, params?: Map<string, any> }): Observable<T> {
        let httpParams = new HttpParams();
        params?.forEach((value: any, key: string) => {
          if (value !== null && value !== undefined  && value !== '')
            httpParams = httpParams.append(key, `${value}`);
        })
        return this._httpClient.get<T>(url, { params: httpParams });
      }
    /* fetch api data */
    getData({ url, params,getHeadersBody,paramsToConcat }: { url: string, params?: Map<string, any>,getHeadersBody?:boolean,paramsToConcat?: any }): Observable<T | T[] | HttpResponse<any> | any > {
        let httpParams = new HttpParams();
        params?.forEach((value :any, key:string) => {
            if (value !== null && value !== undefined  && value !== '')
                httpParams =   httpParams.append(key, value);
        })
        const requestOptions: any = getHeadersBody ? { observe: 'response', params: httpParams } : paramsToConcat ? { ...paramsToConcat, params: httpParams } : { params: httpParams };
        return this._httpClient.get<T | T[] | HttpResponse<any> | any>(url, requestOptions);
    }
    /* post data */
    postData({ url,dataToPost, params }: { url: string,dataToPost:any, params?: Map<string, any> }): Observable<T> {
        let httpParams = new HttpParams();
        params?.forEach((value :any, key:string) => {
            if (value !== null && value !== undefined  && value !== '')
                httpParams=  httpParams.append(key, `${value}`);
        })
        return this._httpClient.post<T>(url,dataToPost, { params: httpParams });
    }

    /* update data */
    updateData({ url, dataToUpdate, params }: { url: string, dataToUpdate: any, params?: Map<string, any> }): Observable<T> {
        let httpParams = new HttpParams();
        params?.forEach((value :any, key:string) => {
            if (value !== null && value !== undefined  && value !== '')
                httpParams = httpParams.append(key, `${value}`);
        })
        return this._httpClient.put<T>(url, dataToUpdate, { params: httpParams });
    }

    /* delete data */
    deleteData({ url, params }: { url: string, params?: Map<string, any> }): Observable<T> {
        let httpParams = new HttpParams();
        params?.forEach((value :any, key:string) => {
            if (value !== null && value !== undefined  && value !== '')
                httpParams = httpParams.append(key, `${value}`);
        })
        return this._httpClient.delete<T>(url, { params: httpParams });
    }
}
